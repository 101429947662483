<template>
  <v-app>
    <v-app-bar app fixed clipped-left density="compact" color="primary">
      <v-toolbar-title style="color: white">Njorda Developer Portal</v-toolbar-title>
    </v-app-bar>
    <v-navigation-drawer v-model:rail="drawerMini" permanent clipped app>
      <v-list density="compact">
        <v-list-item
          v-for="(item, i) in items"
          :key="i"
          :to="item.to"
          density="compact"
          router
          exact
          :class="{
            divider: item.type === 'divider',
          }"
        >
          <template v-if="item.icon" #prepend>
            <v-icon>{{ item.icon }}</v-icon>
          </template>
          <v-list-item-title v-if="item.type !== 'divider'" style="font-size: 14px">
            {{ item.title }}
          </v-list-item-title>
          <v-divider v-else />
        </v-list-item>
      </v-list>
      <template #append>
        <v-btn icon class="my-1" style="margin-left: 10px" @click.stop.prevent="drawerMini = !drawerMini">
          <v-icon>{{ drawerMinifyIcon }}</v-icon>
        </v-btn>
      </template>
    </v-navigation-drawer>

    <v-main>
      <slot />
    </v-main>
  </v-app>
</template>

<script setup lang="ts">
import { useAppStore } from "~/store/app";
import { storeToRefs } from "pinia";

const drawerMini = ref(false);
const items = [
  {
    icon: "mdi-apps",
    title: "Home Dashboard",
    to: "/developer",
  },
  {
    type: "divider",
  },
  {
    type: "subheader",
    title: "B2B",
  },
  {
    icon: "mdi-account",
    title: "User Data",
    to: "/developer/user-list",
  },
  {
    icon: "mdi-account",
    title: "Clients Data Sessions",
    to: "/developer/client-details",
  },
  {
    icon: "mdi-database-clock-outline",
    title: "Data sessions",
    to: "/developer/data-sessions",
  },
  {
    type: "divider",
  },
  {
    type: "subheader",
    title: "Tools",
  },
  {
    icon: "mdi-ab-testing",
    title: "Integration Tests",
    to: "/developer/integration-tests",
  },
  {
    icon: "mdi-office-building-cog",
    title: "OrgApp Export/Import",
    to: "/developer/organizations",
  },
  {
    icon: "mdi-briefcase",
    title: "Portfolio Calc",
    to: "/developer/portfolio-data",
  },
  {
    icon: "mdi-queue-first-in-last-out",
    title: "Trigger Task",
    to: "/developer/trigger-task",
  },
  {
    type: "divider",
  },
  {
    type: "subheader",
    title: "DataApp",
  },
  {
    icon: "mdi-finance",
    title: "Instrument Data",
    to: "/developer/instrument-list",
  },
  {
    icon: "mdi-trophy-broken",
    title: "Instrument issues",
    to: "/developer/instrument-issues",
  },
  {
    type: "divider",
  },
  {
    type: "subheader",
    title: "FrontEnd",
  },
  {
    icon: "mdi-widgets",
    title: "UI Components",
    to: "/developer/component-gallery",
  },
  {
    icon: "mdi-inbox-arrow-down",
    title: "Embed Pages",
    to: "/developer/embed",
  },
  {
    type: "divider",
  },
  {
    type: "subheader",
    title: "AI",
  },
  {
    icon: "mdi-robot",
    title: "Run Eval",
    to: "/developer/ai-run-eval",
  },
  {
    type: "divider",
  },
  {
    type: "subheader",
    title: "API",
  },
  {
    icon: "mdi-api",
    title: "B2C APP API",
    to: "/developer/api/app",
  },
  {
    icon: "mdi-api",
    title: "B2B API V1",
    to: "/developer/api/b2b",
  },
  {
    icon: "mdi-api",
    title: "B2B API V2",
    to: "/developer/api/b2b-v2",
  },
  {
    icon: "mdi-api",
    title: "Admin API V1",
    to: "/developer/api/admin",
  },
  {
    icon: "mdi-api",
    title: "Connect API V1",
    to: "/developer/api/connect",
  },
];

const { layoutSettings } = storeToRefs(useAppStore());

const drawerMinifyIcon = computed(() => {
  if (drawerMini.value) {
    return "n:chevron-right";
  } else {
    return "n:chevron-left";
  }
});

const favicon = computed(() => {
  return layoutSettings.value?.favicon;
});

useHead({
  link: [
    {
      hid: "icon",
      rel: "icon",
      type: "image/x-icon",
      href: favicon,
    },
  ],
});
</script>

<style lang="scss" scoped>
.toolbar-white-box {
  color: white;
  background-color: rgb(255 255 255 / 20%);
  border-radius: 4px;
}

.only-bottom-border {
  border-top: none !important;
  border-right: none !important;
  border-left: none !important;
}

.divider {
  min-height: fit-content !important;
}
</style>
